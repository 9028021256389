import t12_1 from "./T12 (1).webp";
import t12_2 from "./T12 (2).webp";
import t12_3 from "./T12 (3).webp";
import t12_4 from "./T12 (4).webp";
import t12_5 from "./T12 (5).webp";
import t12_6 from "./T12 (6).webp";
import t12_7 from "./T12 (7).webp";

const imgLayton12 = [t12_1, t12_2, t12_3, t12_4, t12_5, t12_6, t12_7];

export default imgLayton12;

import lyndont12_1 from "./T12 (1).webp";
import lyndont12_2 from "./T12 (2).webp";
import lyndont12_3 from "./T12 (3).webp";
import lyndont12_4 from "./T12 (4).webp";
import lyndont12_5 from "./T12 (5).webp";

const imgLyndon12 = [
  lyndont12_1,
  lyndont12_2,
  lyndont12_3,
  lyndont12_4,
  lyndont12_5,
];

export default imgLyndon12;

import imgKiyomi81 from "./img-kiyomi8 (1).webp";
import imgKiyomi82 from "./img-kiyomi8 (2).webp";
import imgKiyomi83 from "./img-kiyomi8 (3).webp";
import imgKiyomi84 from "./img-kiyomi8 (4).webp";
import imgKiyomi85 from "./img-kiyomi8 (5).webp";
import imgKiyomi86 from "./img-kiyomi8 (6).webp";

const imgKiyomi8 = [
  imgKiyomi81,
  imgKiyomi82,
  imgKiyomi83,
  imgKiyomi84,
  imgKiyomi85,
  imgKiyomi86,
];

export default imgKiyomi8;

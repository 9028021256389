import imgTresor1 from "./img-tresor (1).webp";
import imgTresor2 from "./img-tresor (2).webp";
import imgTresor3 from "./img-tresor (3).webp";
import imgTresor4 from "./img-tresor (4).webp";
import imgTresor5 from "./img-tresor (5).webp";
import imgTresor6 from "./img-tresor (6).webp";
import imgTresor7 from "./img-tresor (7).webp";
import imgTresor8 from "./img-tresor (8).webp";
import imgTresor9 from "./img-tresor (9).webp";
import imgTresor10 from "./img-tresor (10).webp";
import imgTresor11 from "./img-tresor (11).webp";
import imgTresor12 from "./img-tresor (12).webp";
import imgTresor13 from "./img-tresor (13).webp";
import imgTresor14 from "./img-tresor (14).webp";
import imgTresor15 from "./img-tresor (15).webp";
import imgTresor16 from "./img-tresor (16).webp";
import imgTresor17 from "./img-tresor (17).webp";
import imgTresor18 from "./img-tresor (18).webp";
import imgTresor19 from "./img-tresor (19).webp";
import imgTresor20 from "./img-tresor (20).webp";

const imgTresor = [
  imgTresor1,
  imgTresor2,
  imgTresor3,
  imgTresor4,
  imgTresor5,
  imgTresor6,
  imgTresor7,
  imgTresor8,
  imgTresor9,
  imgTresor10,
  imgTresor11,
  imgTresor12,
  imgTresor13,
  imgTresor14,
  imgTresor15,
  imgTresor16,
  imgTresor17,
  imgTresor18,
  imgTresor19,
  imgTresor20,
];

export default imgTresor;

import imgFleekhauz1 from "./img-fleekhauz (1).webp";
import imgFleekhauz2 from "./img-fleekhauz (2).webp";
import imgFleekhauz3 from "./img-fleekhauz (3).webp";
import imgFleekhauz4 from "./img-fleekhauz (4).webp";
import imgFleekhauz5 from "./img-fleekhauz (5).webp";

const imgFleekhauz = [
  imgFleekhauz1,
  imgFleekhauz2,
  imgFleekhauz3,
  imgFleekhauz4,
  imgFleekhauz5,
];

export default imgFleekhauz;

import imgEnchante121 from "./img-enchante12 (1).webp";
import imgEnchante122 from "./img-enchante12 (2).webp";
import imgEnchante123 from "./img-enchante12 (3).webp";
import imgEnchante124 from "./img-enchante12 (4).webp";

const imgEnchante12 = [
  imgEnchante121,
  imgEnchante122,
  imgEnchante123,
  imgEnchante124,
];

export default imgEnchante12;

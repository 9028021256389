import fasZora1 from "./fas-zora (1).webp";
import fasZora2 from "./fas-zora (2).webp";
import fasZora3 from "./fas-zora (3).webp";
import fasZora4 from "./fas-zora (4).webp";
import fasZora5 from "./fas-zora (5).webp";
import fasZora6 from "./fas-zora (6).webp";

const faszora = [fasZora1, fasZora2, fasZora3, fasZora4, fasZora5, fasZora6];

export default faszora;

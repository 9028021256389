import lyndont15_1 from "./T15 (1).webp";
import lyndont15_2 from "./T15 (2).webp";
import lyndont15_3 from "./T15 (3).webp";
import lyndont15_4 from "./T15 (4).webp";
import lyndont15_5 from "./T15 (5).webp";

const imgLyndon15 = [
  lyndont15_1,
  lyndont15_2,
  lyndont15_3,
  lyndont15_4,
  lyndont15_5,
];

export default imgLyndon15;

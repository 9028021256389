import imgKiyomi101 from "./img-kiyomi10 (1).webp";
import imgKiyomi102 from "./img-kiyomi10 (2).webp";
import imgKiyomi103 from "./img-kiyomi10 (3).webp";
import imgKiyomi104 from "./img-kiyomi10 (4).webp";
import imgKiyomi105 from "./img-kiyomi10 (5).webp";
import imgKiyomi106 from "./img-kiyomi10 (6).webp";

const imgKiyomi10 = [
  imgKiyomi101,
  imgKiyomi102,
  imgKiyomi103,
  imgKiyomi104,
  imgKiyomi105,
  imgKiyomi106,
];

export default imgKiyomi10;

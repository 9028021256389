import Aetherhero from "./aether.webp";
import Blizfieldhero from "./blizfield.webp";
import Caelushero from "./caelus.webp";
import Enchantehero from "./enchante.webp";
import Eonnahero from "./eonna.webp";
import Fleekhauzhero from "./fleekhauz.webp";
import Hierahero from "./hiera.webp";
import Homehero from "./home.webp";
import Myzahero from "./myza.webp";
import Navaparkhero from "./navapark.webp";
import Tanakayuhero from "./tanakayu.webp";
import Terraviahero from "./terravia.webp";
import Tresorhero from "./tresor.webp";
import Yuthicahero from "./yuthica.webp";
import Zorahero from "./zora.webp";

const Heroimages = {
  Aetherhero,
  Blizfieldhero,
  Caelushero,
  Enchantehero,
  Eonnahero,
  Fleekhauzhero,
  Hierahero,
  Homehero,
  Myzahero,
  Navaparkhero,
  Tanakayuhero,
  Terraviahero,
  Tresorhero,
  Yuthicahero,
  Zorahero,
};

export default Heroimages;

import React from "react";
import "./aboutc.scss";

const aboutc = ({ judul, subjudul }) => {
  return (
    <div className="aboutc">
      <div className="container-aboutc">
        <div className="judul-aboutc">{judul}</div>
        <div className="subjudul-aboutc">{subjudul}</div>
      </div>
    </div>
  );
};

export default aboutc;

import Aetherhs from "./aetherhs.webp";
import Blizfieldhs from "./blizfieldhs.webp";
import Caelushs from "./caelushs.webp";
import Enchantehs from "./enchantehs.webp";
import Eonnahs from "./eonnahs.webp";
import Fleekhauzhs from "./fleekhauzhs.webp";
import Hierahs from "./hierahs.webp";
import Homehs from "./home.webp";
import Myzahs from "./myzahs.webp";
import Navaparkhs from "./navaparkhs.webp";
import Tanakayuhs from "./tanakayuhs.webp";
import Terraviahs from "./terraviahs.webp";
import Tresorhs from "./tresorhs.webp";
import Yuthicahs from "./yuthicahs.webp";
import Zorahs from "./zorahs.webp";
import Wynyardhs from "./wynyardhs.webp";
import Belovahs from "./belovahs.webp";

const hsimages = {
  Aetherhs,
  Blizfieldhs,
  Caelushs,
  Enchantehs,
  Eonnahs,
  Fleekhauzhs,
  Hierahs,
  Homehs,
  Myzahs,
  Navaparkhs,
  Tanakayuhs,
  Terraviahs,
  Tresorhs,
  Yuthicahs,
  Zorahs,
  Wynyardhs,
  Belovahs,
};

export default hsimages;

import fasMyza1 from "./fas-myza (1).webp";
import fasMyza2 from "./fas-myza (2).webp";
import fasMyza3 from "./fas-myza (3).webp";
import fasMyza4 from "./fas-myza (4).webp";
import fasMyza5 from "./fas-myza (5).webp";
import fasMyza6 from "./fas-myza (6).webp";

const fasmyza = [fasMyza1, fasMyza2, fasMyza3, fasMyza4, fasMyza5, fasMyza6];

export default fasmyza;

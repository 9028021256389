import { faBuilding, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { HashLink } from "react-router-hash-link";
import "./residentials.scss";
import datar from "../../../component/cardprodukhome/cardprodukresidentials.json";
import Cardprodukhome from "../../../component/cardprodukhome/cardprodukhome.jsx";
import logop from "../../../asset/logo/logo";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import sliderproduk from "../../../asset/sliderproduk/sliderproduk.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Residentials = () => {
  const wap = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281319499502&text=Halo%20Reza,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://marketingbsdcity.com/%20&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768,
        lazyload: true, // Adjust breakpoint for mobile devices
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile (optional)
          swipeToSlide: true,

          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="residentials">
      <div className="container-residentials">
        <div className="containerheader-residentials">
          <div className="navigasi">
            <HashLink className="link" smooth to="/">
              <div className="line">
                <FontAwesomeIcon icon={faHouse} />
                &nbsp;&nbsp;Home
              </div>
            </HashLink>
            <HashLink className="link" smooth to="/commercials">
              <div className="line">
                <FontAwesomeIcon icon={faBuilding} />
                &nbsp;&nbsp;Commercial
              </div>
            </HashLink>
          </div>
          <div className="h1">Pilihan Rumah BSD City</div>
        </div>
        <div className="dividera"></div>
        <div className="a">
          <div className="carousel-produk">
            <Slider {...settings}>
              {sliderproduk.map((tanamas, index) => (
                <img className="devb" key={index} src={tanamas} alt={"dev"} />
              ))}
            </Slider>
          </div>
          <div className="container-cardproduk">
            {datar.cardr.map((promo, index) => {
              // Determine the logo dynamically
              const fotopromo = logop[promo.logoimg];
              return (
                <Cardprodukhome
                  key={index}
                  judulcard={promo.judulcard} // Dynamically import logo images
                  harga={promo.harga}
                  logoimg={fotopromo}
                  linkpage={promo.linkpage} // Pass the WhatsApp link
                />
              );
            })}
          </div>
          <div className="buttonr">
            <div className="buttonwa2">
              <button onClick={wap} className="whatsapp">
                {" "}
                <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Residentials;

import imgAether1 from "./img-aether (1).webp";
import imgAether2 from "./img-aether (2).webp";
import imgAether3 from "./img-aether (3).webp";
import imgAether4 from "./img-aether (4).webp";
import imgAether5 from "./img-aether (5).webp";
import imgAether6 from "./img-aether (6).webp";
import imgAether7 from "./img-aether (7).webp";
import imgAether8 from "./img-aether (8).webp";

const imgAether = [
  imgAether1,
  imgAether2,
  imgAether3,
  imgAether4,
  imgAether5,
  imgAether6,
  imgAether7,
  imgAether8,
];

export default imgAether;

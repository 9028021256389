import imgFlathouse1 from "./img-flathouse (1).webp";
import imgFlathouse2 from "./img-flathouse (2).webp";
import imgFlathouse3 from "./img-flathouse (3).webp";
import imgFlathouse4 from "./img-flathouse (4).webp";
import imgFlathouse5 from "./img-flathouse (5).webp";
import imgFlathouse6 from "./img-flathouse (6).webp";
import imgFlathouse7 from "./img-flathouse (7).webp";
import imgFlathouse8 from "./img-flathouse (8).webp";
import imgFlathouse9 from "./img-flathouse (9).webp";

const imgFlathouse = [
  imgFlathouse1,
  imgFlathouse2,
  imgFlathouse3,
  imgFlathouse4,
  imgFlathouse5,
  imgFlathouse6,
  imgFlathouse7,
  imgFlathouse8,
  imgFlathouse9,
];

export default imgFlathouse;

import imgBelova1 from "./img-belova (1).webp";
import imgBelova2 from "./img-belova (2).webp";
import imgBelova3 from "./img-belova (3).webp";
import imgBelova4 from "./img-belova (4).webp";
import imgBelova5 from "./img-belova (5).webp";
import imgBelova6 from "./img-belova (6).webp";
import imgBelova7 from "./img-belova (7).webp";
import imgBelova8 from "./img-belova (8).webp";
import imgBelova9 from "./img-belova (9).webp";
import imgBelova10 from "./img-belova (10).webp";

const imgBelova = [
  imgBelova1,
  imgBelova2,
  imgBelova3,
  imgBelova4,
  imgBelova5,
  imgBelova6,
  imgBelova7,
  imgBelova8,
  imgBelova9,
  imgBelova10,
];

export default imgBelova;

import fasTanakayu1 from "./fas-tanakayu (1).webp";
import fasTanakayu2 from "./fas-tanakayu (2).webp";
import fasTanakayu3 from "./fas-tanakayu (3).webp";
import fasTanakayu4 from "./fas-tanakayu (4).webp";
import fasTanakayu5 from "./fas-tanakayu (5).webp";
import fasTanakayu6 from "./fas-tanakayu (6).webp";

const fastanakayu = [
  fasTanakayu1,
  fasTanakayu2,
  fasTanakayu3,
  fasTanakayu4,
  fasTanakayu5,
  fasTanakayu6,
];

export default fastanakayu;

import imgAdoraprime1 from "./img-adoraprime (1).webp";
import imgAdoraprime2 from "./img-adoraprime (2).webp";
import imgAdoraprime3 from "./img-adoraprime (3).webp";
import imgAdoraprime4 from "./img-adoraprime (4).webp";
import imgAdoraprime5 from "./img-adoraprime (5).webp";
import imgAdoraprime6 from "./img-adoraprime (6).webp";

const imgAdoraprime = [
  imgAdoraprime1,
  imgAdoraprime2,
  imgAdoraprime3,
  imgAdoraprime4,
  imgAdoraprime5,
  imgAdoraprime6,
];

export default imgAdoraprime;

import fasCaelus1 from "./fas-caelus (1).webp";
import fasCaelus2 from "./fas-caelus (2).webp";
import fasCaelus3 from "./fas-caelus (3).webp";
import fasCaelus4 from "./fas-caelus (4).webp";
import fasCaelus5 from "./fas-caelus (5).webp";
import fasCaelus6 from "./fas-caelus (6).webp";

const fascaelus = [
  fasCaelus1,
  fasCaelus2,
  fasCaelus3,
  fasCaelus4,
  fasCaelus5,
  fasCaelus6,
];

export default fascaelus;

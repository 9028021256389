import imgChava1 from "./img-chava (1).webp";
import imgChava2 from "./img-chava (2).webp";
import imgChava3 from "./img-chava (3).webp";
import imgChava4 from "./img-chava (4).webp";
import imgChava5 from "./img-chava (5).webp";
import imgChava6 from "./img-chava (6).webp";

const imgChava = [
  imgChava1,
  imgChava2,
  imgChava3,
  imgChava4,
  imgChava5,
  imgChava6,
];

export default imgChava;

import imgWynyard71 from "./Wynyard 7.webp";
import imgWynyard72 from "./Wynyard 7 (2).webp";
import imgWynyard73 from "./Wynyard 7 (3).webp";
import imgWynyard74 from "./Wynyard 7 (4).webp";
import imgWynyard75 from "./Wynyard 7 (5).webp";
import imgWynyard76 from "./Wynyard 7 (6).webp";
import imgWynyard77 from "./Wynyard 7 (7).webp";
import imgWynyard78 from "./Wynyard 7 (8).webp";
import imgWynyard79 from "./Wynyard 7 (9).webp";
import imgWynyard710 from "./Wynyard 7 (10).webp";

const imgWynyard7 = [
  imgWynyard71,
  imgWynyard72,
  imgWynyard73,
  imgWynyard74,
  imgWynyard75,
  imgWynyard76,
  imgWynyard77,
  imgWynyard78,
  imgWynyard79,
  imgWynyard710,
];

export default imgWynyard7;

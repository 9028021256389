import fasNavapark1 from "./fas-navapark (1).webp";
import fasNavapark2 from "./fas-navapark (2).webp";
import fasNavapark3 from "./fas-navapark (3).webp";
import fasNavapark4 from "./fas-navapark (4).webp";
import fasNavapark5 from "./fas-navapark (5).webp";
import fasNavapark6 from "./fas-navapark (6).webp";
import fasNavapark7 from "./fas-navapark (7).webp";
import fasNavapark8 from "./fas-navapark (8).webp";

const fasnavapark = [
  fasNavapark1,
  fasNavapark2,
  fasNavapark3,
  fasNavapark4,
  fasNavapark5,
  fasNavapark6,
  fasNavapark7,
  fasNavapark8,
];

export default fasnavapark;

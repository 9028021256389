import lyndont19_1 from "./T19 (1).webp";
import lyndont19_2 from "./T19 (2).webp";
import lyndont19_3 from "./T19 (3).webp";
import lyndont19_4 from "./T19 (4).webp";
import lyndont19_5 from "./T19 (5).webp";

const imgLyndon19 = [
  lyndont19_1,
  lyndont19_2,
  lyndont19_3,
  lyndont19_4,
  lyndont19_5,
];

export default imgLyndon19;

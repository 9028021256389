import React from "react";
import "./tresor.scss";
import data from "../../data.json";
import Heroc from "../../../component/hero/heroc.jsx";
import Aboutc from "../../../component/about/aboutc.jsx";
import Heroimages from "../../../asset/hero/heroimages.js";
import Promoc from "../../../component/promo/promoc.jsx";
import hsimages from "../../../asset/hardsell/hsimages.js";
import prmo from "../../../component/promo/promocard.json";
import Carouselfasc from "../../../component/carouselfas/carouselfasc.jsx";
import fas from "../../../component/carouselfas/carouselfasc.json";
import fastresor from "../../../asset/fascarousel/fas-tresor/fas-tresor.js";
import Cardprodukdetail1 from "../../../component/cardprodukdetail/cardprodukdetail1.jsx";
import dataproduk from "../../../component/cardprodukdetail/cardprodukdetail.json";
import imgTresor from "../../../asset/imgrumah/img-tresor/img-tresor.js";

const imgMap = {
  fastresor,
};
const imageMap = {
  imgTresor,
};

const tresor = () => {
  return (
    <div className="tresor">
      <div className="hero">
        {data.tresorab.map((hero, index) => {
          // Determine the logo dynamically
          const fotopromo = Heroimages[hero.imagehero];
          return (
            <Heroc
              key={index}
              imagehero={fotopromo} // Dynamically import logo images // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="about">
        {data.tresorab.map((home, index) => {
          // Determine the logo dynamically
          return (
            <Aboutc
              key={index}
              judul={home.judul}
              subjudul={home.subjudul} // Dynamically import logo images // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="container-promo">
        {prmo.Tresor.map((promo, index) => {
          // Determine the logo dynamically
          const fotopromo = hsimages[promo.imagehs];
          return (
            <Promoc
              key={index}
              imagehs={fotopromo} // Dynamically import logo images
              titlepromo={promo.titlepromo}
              details={promo.details}
              whatsappLink={promo.whatsappLink}
              quote={promo.quote} // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="carouselfas">
        {fas.fasTresor.map((slide, index) => {
          return <Carouselfasc key={index} carousel={imgMap[slide.carousel]} />;
        })}
      </div>
      <div className="produkcontainer">
        {dataproduk.Tresorp.map((card, index) => {
          return (
            <Cardprodukdetail1
              key={index}
              src={imageMap[card.imagesKey]}
              title={card.title}
              sub={card.sub}
              subt={card.subt}
              details={card.details}
              price={card.price}
              whatsappLink={card.whatsappLink}
            />
          );
        })}
      </div>
    </div>
  );
};

export default tresor;

import Sliderruko1 from "./sliderruko (1).webp";
import Sliderruko2 from "./sliderruko (2).webp";
import Sliderruko3 from "./sliderruko (3).webp";
import Sliderruko4 from "./sliderruko (4).webp";
import Sliderruko5 from "./sliderruko (5).webp";
import Sliderruko6 from "./sliderruko (6).webp";
import Sliderruko7 from "./sliderruko (7).webp";
import Sliderruko8 from "./sliderruko (8).webp";
import Sliderruko9 from "./sliderruko (9).webp";
import Sliderruko10 from "./sliderruko (10).webp";

const sliderruko = [
  Sliderruko1,
  Sliderruko2,
  Sliderruko3,
  Sliderruko4,
  Sliderruko5,
  Sliderruko6,
  Sliderruko7,
  Sliderruko8,
  Sliderruko9,
  Sliderruko10,
];

export default sliderruko;

import imgBlizfield1 from "./img-blizfield (1).webp";
import imgBlizfield2 from "./img-blizfield (2).webp";
import imgBlizfield3 from "./img-blizfield (3).webp";
import imgBlizfield4 from "./img-blizfield (4).webp";
import imgBlizfield5 from "./img-blizfield (5).webp";
import imgBlizfield6 from "./img-blizfield (6).webp";
import imgBlizfield7 from "./img-blizfield (7).webp";
import imgBlizfield8 from "./img-blizfield (8).webp";

const imgBlizfield = [
  imgBlizfield1,
  imgBlizfield2,
  imgBlizfield3,
  imgBlizfield4,
  imgBlizfield5,
  imgBlizfield6,
  imgBlizfield7,
  imgBlizfield8,
];

export default imgBlizfield;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAngleUp,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./newlaunchc.scss";

const Newlaunchc = ({
  imagehs,
  titlepromo,
  details,
  whatsappLink,
  pageLink,
}) => {
  const renderDetail = (detail) => {
    const parts = detail.split(" ");
    const iconClass = parts.shift(); // Remove and get the first part as icon class

    // Determine FontAwesome icon based on class name
    let iconComponent = null;
    switch (iconClass) {
      case "fa-check":
        iconComponent = <FontAwesomeIcon icon={faCheckDouble} />;
        break;
      default:
        break;
    }

    // Render the detail item
    return (
      <div className="penawaranpoin">
        {iconComponent && <span>{iconComponent}</span>}
        <span style={{ color: "#000000" }}>&nbsp;&nbsp;{parts.join(" ")}</span>
      </div>
    );
  };

  return (
    <div className="container-newlaunchc">
      <div className="newlaunchc">
        <div className="containercontent">
          <div className="judul-newlaunchc">{titlepromo}</div>
          <div className="pointpenawaran">
            {Object.values(details).map((detail, index) =>
              renderDetail(detail)
            )}
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <div className="button-wa-newlaunchc">
            <button
              onClick={() => window.open(whatsappLink, "_blank")}
              className="whatsapp"
            >
              <FontAwesomeIcon icon={faWhatsapp} /> Get More Info
            </button>
            <button
              onClick={() => window.open(pageLink, "_blank")}
              className="whatsapp"
            >
              Detail Product <FontAwesomeIcon icon={faAngleRight} />
            </button>
          </div>
        </div>
        <div className="containergambar">
          <img className="penawarangambar" src={imagehs} alt="hardsell" />
        </div>
      </div>
    </div>
  );
};

export default Newlaunchc;

import React from "react";
import "./home.scss";
import ScrollToHashElement from "./ScrollToHashElement";
import Hero from "./section/hero/hero.jsx";
import About from "./section/about/about.jsx";
import Promo from "./section/promo/promo.jsx";
import Newlaunch from "./section/newlaunch/newlaunch.jsx";
import Devbsd from "./section/devbsd/devbsd.jsx";
import Produk from "./section/produk/produk.jsx";

const home = () => {
  return (
    <div className="home">
      <ScrollToHashElement />
      <Hero />
      <About />
      <Promo />
      <Newlaunch />
      <Devbsd />
      <Produk />
    </div>
  );
};

export default home;

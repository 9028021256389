import imgVasya1 from "./img-vasya (1).webp";
import imgVasya2 from "./img-vasya (2).webp";
import imgVasya3 from "./img-vasya (3).webp";
import imgVasya4 from "./img-vasya (4).webp";
import imgVasya5 from "./img-vasya (5).webp";
import imgVasya6 from "./img-vasya (6).webp";
import imgVasya7 from "./img-vasya (7).webp";
import imgVasya8 from "./img-vasya (8).webp";
import imgVasya9 from "./img-vasya (9).webp";

const imgVasya = [
  imgVasya1,
  imgVasya2,
  imgVasya3,
  imgVasya4,
  imgVasya5,
  imgVasya6,
  imgVasya7,
  imgVasya8,
  imgVasya9,
];

export default imgVasya;

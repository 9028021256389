import React, { useEffect, useState } from "react";
import "./cardprodukdetailn.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faCar, faShower } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";

const mediaMatch = window.matchMedia("(min-width: 768px)");

const Cardprodukdetailn = ({
  title,
  sub,
  subt,
  style,
  src,
  details,
  price,
  whatsappLink,
}) => {
  const settings = {
    dots: src.length > 1,
    arrows: src.length > 1,
    infinite: src.length > 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: src.length > 1,
  };

  const [setIsDesktop] = useState(mediaMatch.matches);
  useEffect(() => {
    const handler = (e) => setIsDesktop(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  }, []);

  return (
    <div className="cardprodn">
      <div className="imgcard">
        <Slider {...settings}>
          {src.map((image, index) => (
            <img
              key={index}
              className="image"
              src={image}
              alt={`Launching ${index + 1}`}
            />
          ))}
        </Slider>
      </div>
      <div className="cardcontentn">
        <div className="det">
          <div className="cardtitle">{title}</div>
          <div className="cardsub">Building Area : {sub}</div>
          <div className="cardsub">Land Area : {subt}</div>
          <div className="cardicon">
            <span>
              <FontAwesomeIcon icon={faBed} />
              &nbsp;: {details.kamar}&nbsp;&nbsp;&nbsp;
            </span>
            <span>
              <FontAwesomeIcon icon={faShower} />
              &nbsp;: {details.mandi}&nbsp;&nbsp;&nbsp;
            </span>
            <span>
              <FontAwesomeIcon icon={faCar} />
              &nbsp;: {details.car}&nbsp;&nbsp;&nbsp;
            </span>
          </div>
        </div>
        <div className="cardprice">{price}</div>
        <div className="cardbutton">
          <button
            onClick={() => window.open(whatsappLink, "_blank")}
            className="buttonwa"
          >
            Whatsapp
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cardprodukdetailn;

import Sliderproduk1 from "./sliderproduk (1).webp";
import Sliderproduk2 from "./sliderproduk (2).webp";
import Sliderproduk3 from "./sliderproduk (3).webp";
import Sliderproduk4 from "./sliderproduk (4).webp";
import Sliderproduk5 from "./sliderproduk (5).webp";
import Sliderproduk6 from "./sliderproduk (6).webp";
import Sliderproduk7 from "./sliderproduk (7).webp";
import Sliderproduk8 from "./sliderproduk (8).webp";
import Sliderproduk9 from "./sliderproduk (9).webp";
import Sliderproduk10 from "./sliderproduk (10).webp";
import Sliderproduk11 from "./sliderproduk (11).webp";

const sliderproduk = [
  Sliderproduk1,
  Sliderproduk2,
  Sliderproduk3,
  Sliderproduk4,
  Sliderproduk5,
  Sliderproduk6,
  Sliderproduk7,
  Sliderproduk8,
  Sliderproduk9,
  Sliderproduk10,
  Sliderproduk11,
];

export default sliderproduk;

import imgWynyard91 from "./Wynyard 9.webp";
import imgWynyard92 from "./Wynyard 9 (2).webp";
import imgWynyard93 from "./Wynyard 9 (3).webp";
import imgWynyard94 from "./Wynyard 9 (4).webp";
import imgWynyard95 from "./Wynyard 9 (5).webp";
import imgWynyard96 from "./Wynyard 9 (6).webp";
import imgWynyard97 from "./Wynyard 9 (7).webp";
import imgWynyard98 from "./Wynyard 9 (8).webp";
import imgWynyard99 from "./Wynyard 9 (9).webp";
import imgWynyard910 from "./Wynyard 9 (10).webp";

const imgWynyard9 = [
  imgWynyard91,
  imgWynyard92,
  imgWynyard93,
  imgWynyard94,
  imgWynyard95,
  imgWynyard96,
  imgWynyard97,
  imgWynyard98,
  imgWynyard99,
  imgWynyard910,
];

export default imgWynyard9;

import React from "react";
import "./enchante.scss";
import data from "../../data.json";
import Heroc from "../../../component/hero/heroc.jsx";
import Aboutc from "../../../component/about/aboutc.jsx";
import Heroimages from "../../../asset/hero/heroimages.js";
import Promoc from "../../../component/promo/promoc.jsx";
import hsimages from "../../../asset/hardsell/hsimages.js";
import prmo from "../../../component/promo/promocard.json";
import Carouselfasc from "../../../component/carouselfas/carouselfasc.jsx";
import fas from "../../../component/carouselfas/carouselfasc.json";
import fasenchante from "../../../asset/fascarousel/fas-enchante/fas-enchante.js";
import dataproduk from "../../../component/cardprodukdetail/cardprodukdetail.json";
import imgEnchante10 from "../../../asset/imgrumah/img-enchante/img-enchante10/img-enchante10.js";
import imgEnchante12 from "../../../asset/imgrumah/img-enchante/img-enchante12/img-enchante12.js";
import imgEnchante18 from "../../../asset/imgrumah/img-enchante/img-enchante18/img-enchante18.js";
import Cardprodukdetailn from "../../../component/cardprodukdetail/cardprodukdetailn.jsx";
const imgMap = {
  fasenchante,
};
const imageMap = {
  imgEnchante18,
  imgEnchante12,
  imgEnchante10,
};

const enchante = () => {
  return (
    <div className="enchante">
      <div className="hero">
        {data.enchanteab.map((hero, index) => {
          // Determine the logo dynamically
          const fotopromo = Heroimages[hero.imagehero];
          return (
            <Heroc
              key={index}
              imagehero={fotopromo} // Dynamically import logo images // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="about">
        {data.enchanteab.map((home, index) => {
          // Determine the logo dynamically
          return (
            <Aboutc
              key={index}
              judul={home.judul}
              subjudul={home.subjudul} // Dynamically import logo images // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="container-promo">
        {prmo.Enchante.map((promo, index) => {
          // Determine the logo dynamically
          const fotopromo = hsimages[promo.imagehs];
          return (
            <Promoc
              key={index}
              imagehs={fotopromo} // Dynamically import logo images
              titlepromo={promo.titlepromo}
              details={promo.details}
              whatsappLink={promo.whatsappLink}
              quote={promo.quote} // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="carouselfas">
        {fas.fasEnchante.map((slide, index) => {
          return <Carouselfasc key={index} carousel={imgMap[slide.carousel]} />;
        })}
      </div>
      <div className="produkcontainer">
        {dataproduk.Enchantep.map((card, index) => {
          return (
            <Cardprodukdetailn
              key={index}
              src={imageMap[card.imagesKey]}
              title={card.title}
              sub={card.sub}
              subt={card.subt}
              details={card.details}
              price={card.price}
              whatsappLink={card.whatsappLink}
            />
          );
        })}
      </div>
    </div>
  );
};

export default enchante;

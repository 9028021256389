import fasTresor1 from "./fas-tresor (1).webp";
import fasTresor2 from "./fas-tresor (2).webp";
import fasTresor3 from "./fas-tresor (3).webp";
import fasTresor4 from "./fas-tresor (4).webp";
import fasTresor5 from "./fas-tresor (5).webp";

const fastresor = [
  fasTresor1,
  fasTresor2,
  fasTresor3,
  fasTresor4,
  fasTresor5,
];

export default fastresor;

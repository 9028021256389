import fasEonna1 from "./fas-eona (1).webp";
import fasEonna2 from "./fas-eona (2).webp";
import fasEonna3 from "./fas-eona (3).webp";
import fasEonna4 from "./fas-eona (4).webp";
import fasEonna5 from "./fas-eona (5).webp";
import fasEonna6 from "./fas-eona (6).webp";

const faseonna = [
  fasEonna1,
  fasEonna2,
  fasEonna3,
  fasEonna4,
  fasEonna5,
  fasEonna6,
];

export default faseonna;

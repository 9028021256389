import imgCozmohouse1 from "./img-cozmohouse (1).webp";
import imgCozmohouse2 from "./img-cozmohouse (2).webp";
import imgCozmohouse3 from "./img-cozmohouse (3).webp";
import imgCozmohouse4 from "./img-cozmohouse (4).webp";
import imgCozmohouse5 from "./img-cozmohouse (5).webp";

const imgCozmohouse = [
  imgCozmohouse1,
  imgCozmohouse2,
  imgCozmohouse3,
  imgCozmohouse4,
  imgCozmohouse5,
];

export default imgCozmohouse;

import React from "react";
import "./terravia.scss";
import data from "../../data.json";
import Heroc from "../../../component/hero/heroc.jsx";
import Aboutc from "../../../component/about/aboutc.jsx";
import Carouselfasc from "../../../component/carouselfas/carouselfasc.jsx";
import Heroimages from "../../../asset/hero/heroimages.js";
import Promoc from "../../../component/promo/promoc.jsx";
import hsimages from "../../../asset/hardsell/hsimages.js";
import prmo from "../../../component/promo/promocard.json";
import logo from "./adora.webp";
import fas from "../../../component/carouselfas/carouselfasc.json";
import fasterravia from "../../../asset/fascarousel/fas-terravia/fas-terravia.js";
import imgAdora from "../../../asset/imgrumah/img-terravia/img-adora/img-adora.js";
import imgAdoraluxe from "../../../asset/imgrumah/img-terravia/img-adoraluxe/img-adoraluxe.js";
import imgAdoraprime from "../../../asset/imgrumah/img-terravia/img-adoraprime/img-adoraprime.js";
import imgBelova from "../../../asset/imgrumah/img-terravia/img-belova/img-belova.js";
import dataproduk from "../../../component/cardprodukdetail/cardprodukdetail.json";
import Cardprodukdetail from "../../../component/cardprodukdetail/cardprodukdetail.jsx";
const imgMap = {
  fasterravia,
};
const imageMap = {
  imgAdora,
  imgAdoraluxe,
  imgAdoraprime,
  imgBelova,
};

const terravia = () => {
  return (
    <div className="terravia">
      <div className="hero">
        {data.terraviaab.map((hero, index) => {
          // Determine the logo dynamically
          const fotopromo = Heroimages[hero.imagehero];
          return (
            <Heroc
              key={index}
              imagehero={fotopromo} // Dynamically import logo images // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="about">
        {data.terraviaab.map((home, index) => {
          // Determine the logo dynamically
          return (
            <Aboutc
              key={index}
              judul={home.judul}
              subjudul={home.subjudul} // Dynamically import logo images // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="logoadora">
        <img src={logo} alt="adora" />
      </div>
      <div className="container-promo">
        {prmo.Terravia.map((promo, index) => {
          // Determine the logo dynamically
          const fotopromo = hsimages[promo.imagehs];
          return (
            <Promoc
              key={index}
              imagehs={fotopromo} // Dynamically import logo images
              titlepromo={promo.titlepromo}
              details={promo.details}
              whatsappLink={promo.whatsappLink}
              quote={promo.quote} // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="carouselfas">
        {fas.fasTerravia.map((slide, index) => {
          return <Carouselfasc key={index} carousel={imgMap[slide.carousel]} />;
        })}
      </div>
      <div className="produkcontainer">
        {dataproduk.Terraviap.map((card, index) => {
          return (
            <Cardprodukdetail
              key={index}
              src={imageMap[card.imagesKey]}
              title={card.title}
              sub={card.sub}
              subt={card.subt}
              details={card.details}
              price={card.price}
              whatsappLink={card.whatsappLink}
            />
          );
        })}
      </div>
    </div>
  );
};

export default terravia;

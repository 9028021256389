import imgBreezyhouse1 from "./img-breezyhouse (1).webp";
import imgBreezyhouse2 from "./img-breezyhouse (2).webp";
import imgBreezyhouse3 from "./img-breezyhouse (3).webp";
import imgBreezyhouse4 from "./img-breezyhouse (4).webp";
import imgBreezyhouse5 from "./img-breezyhouse (5).webp";
import imgBreezyhouse6 from "./img-breezyhouse (6).webp";
import imgBreezyhouse7 from "./img-breezyhouse (7).webp";

const imgBreezyhouse = [
  imgBreezyhouse1,
  imgBreezyhouse2,
  imgBreezyhouse3,
  imgBreezyhouse4,
  imgBreezyhouse5,
  imgBreezyhouse6,
  imgBreezyhouse7,
];

export default imgBreezyhouse;

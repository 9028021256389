import imgAdoraluxe1 from "./img-adoraluxe (1).webp";
import imgAdoraluxe2 from "./img-adoraluxe (2).webp";
import imgAdoraluxe3 from "./img-adoraluxe (3).webp";
import imgAdoraluxe4 from "./img-adoraluxe (4).webp";
import imgAdoraluxe5 from "./img-adoraluxe (5).webp";
import imgAdoraluxe6 from "./img-adoraluxe (6).webp";
import imgAdoraluxe7 from "./img-adoraluxe (7).webp";
import imgAdoraluxe8 from "./img-adoraluxe (8).webp";
import imgAdoraluxe9 from "./img-adoraluxe (9).webp";
import imgAdoraluxe10 from "./img-adoraluxe (10).webp";
import imgAdoraluxe11 from "./img-adoraluxe (11).webp";
import imgAdoraluxe12 from "./img-adoraluxe (12).webp";
import imgAdoraluxe13 from "./img-adoraluxe (13).webp";
import imgAdoraluxe14 from "./img-adoraluxe (14).webp";

const imgAdoraluxe = [
  imgAdoraluxe1,
  imgAdoraluxe2,
  imgAdoraluxe3,
  imgAdoraluxe4,
  imgAdoraluxe5,
  imgAdoraluxe6,
  imgAdoraluxe7,
  imgAdoraluxe8,
  imgAdoraluxe9,
  imgAdoraluxe10,
  imgAdoraluxe11,
  imgAdoraluxe12,
  imgAdoraluxe13,
  imgAdoraluxe14,
];

export default imgAdoraluxe;

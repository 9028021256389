import React from "react";
import Aboutc from "../../../../component/about/aboutc.jsx";
import data from "../../../data.json";

const about = () => {
  return (
    <div id="about" className="about">
      {data.homeab.map((home, index) => {
        // Determine the logo dynamically
        return (
          <Aboutc
            key={index}
            judul={home.judul}
            subjudul={home.subjudul} // Dynamically import logo images // Pass the WhatsApp link
          />
        );
      })}
    </div>
  );
};

export default about;

import imgEnchante181 from "./img-enchante18 (1).webp";
import imgEnchante182 from "./img-enchante18 (2).webp";
import imgEnchante183 from "./img-enchante18 (3).webp";
import imgEnchante184 from "./img-enchante18 (4).webp";

const imgEnchante18 = [
  imgEnchante181,
  imgEnchante182,
  imgEnchante183,
  imgEnchante184,
];

export default imgEnchante18;

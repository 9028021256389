import React from "react";
import "./hero-home.scss";

const Heroc = () => {
  return (
    <div className="heroc-home">
      <div className="container-heroc-home"></div>
    </div>
  );
};

export default Heroc;

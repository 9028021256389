import React from "react";
import "./myza.scss";
import data from "../../data.json";
import Heroc from "../../../component/hero/heroc.jsx";
import Aboutc from "../../../component/about/aboutc.jsx";
import Heroimages from "../../../asset/hero/heroimages.js";
import Promoc from "../../../component/promo/promoc.jsx";
import hsimages from "../../../asset/hardsell/hsimages.js";
import prmo from "../../../component/promo/promocard.json";
import myzalogo from "../../../asset/logo/myzalogo.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carouselfasc from "../../../component/carouselfas/carouselfasc.jsx";
import fas from "../../../component/carouselfas/carouselfasc.json";
import fasmyza from "../../../asset/fascarousel/fas-myza/fas-myza.js";
import dataproduk from "../../../component/cardprodukdetail/cardprodukdetail.json";
import Cardprodukdetail from "../../../component/cardprodukdetail/cardprodukdetail.jsx";
import imgBreezyhouse from "../../../asset/imgrumah/img-myza/img-breezyhouse/img-breezyhouse.js";
import imgCozmohouse from "../../../asset/imgrumah/img-myza/img-cozmohouse/img-cozmohouse.js";
import imgFlathouse from "../../../asset/imgrumah/img-myza/img-flathouse/img-flathouse.js";

const imgMap = {
  fasmyza,
};
const imageMap = {
  imgFlathouse,
  imgBreezyhouse,
  imgCozmohouse,
};

const myza = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for your mobile size
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile screens
        },
      },
    ],
  };
  return (
    <div className="myza">
      <div className="hero">
        {data.myzaab.map((hero, index) => {
          // Determine the logo dynamically
          const fotopromo = Heroimages[hero.imagehero];
          return (
            <Heroc
              key={index}
              imagehero={fotopromo} // Dynamically import logo images // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="about">
        {data.myzaab.map((home, index) => {
          // Determine the logo dynamically
          return (
            <Aboutc
              key={index}
              judul={home.judul}
              subjudul={home.subjudul} // Dynamically import logo images // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="logomyza1">
        <div className="carousellogo1">
          <Slider {...settings}>
            {myzalogo.map((image, index) => (
              <img
                className="image-logo"
                key={index}
                src={image}
                alt="foto logo"
              />
            ))}
          </Slider>
        </div>
      </div>
      <div className="container-promo">
        {prmo.Myza.map((promo, index) => {
          // Determine the logo dynamically
          const fotopromo = hsimages[promo.imagehs];
          return (
            <Promoc
              key={index}
              imagehs={fotopromo} // Dynamically import logo images
              titlepromo={promo.titlepromo}
              details={promo.details}
              whatsappLink={promo.whatsappLink}
              quote={promo.quote} // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="carouselfas">
        {fas.fasMyza.map((slide, index) => {
          return <Carouselfasc key={index} carousel={imgMap[slide.carousel]} />;
        })}
      </div>
      <div className="produkcontainer">
        {dataproduk.Myzap.map((card, index) => {
          return (
            <Cardprodukdetail
              key={index}
              src={imageMap[card.imagesKey]}
              title={card.title}
              sub={card.sub}
              subt={card.subt}
              details={card.details}
              price={card.price}
              whatsappLink={card.whatsappLink}
            />
          );
        })}
      </div>
    </div>
  );
};

export default myza;

import React from "react";
import data from "../../../../component/promo/promocard.json";
import Promoc from "../../../../component/promo/promoc";
import hsimages from "../../../../asset/hardsell/hsimages.js";

const promo = () => {
  return (
    <div id="promo" className="promoh">
      <div className="container-promo">
        {data.home.map((promo, index) => {
          // Determine the logo dynamically
          const fotopromo = hsimages[promo.imagehs];
          return (
            <Promoc
              key={index}
              imagehs={fotopromo} // Dynamically import logo images
              titlepromo={promo.titlepromo}
              details={promo.details}
              whatsappLink={promo.whatsappLink}
              quote={promo.quote} // Pass the WhatsApp link
            />
          );
        })}
      </div>
    </div>
  );
};

export default promo;

import React from "react";
import "./cardprodukhome.scss";
import { Link } from "react-router-dom";

const cardprodukhome = ({ linkpage, judulcard, harga, logoimg }) => {
  return (
    <div className="card">
      <div className="container-card">
        <Link className="overlay" to={linkpage}>
          <div className="atas">
            <div className="promos">{judulcard}</div>
            <div className="detail">
              <div className="start">Start From</div>
              <div className="angka">{harga}</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="bawah">
            <div className="logos1">
              <img src={logoimg} alt="logo" />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default cardprodukhome;

import React from "react";
import "./heroc.scss";

const Heroc = ({ imagehero }) => {
  return (
    <div className="heroc">
      <div className="container-heroc">
        <img src={imagehero} alt="hero" />
      </div>
    </div>
  );
};

export default Heroc;

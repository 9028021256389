import React from "react";
import Heroc from "../../../../component/hero/heroc-home.jsx";
import "./hero.scss";

const hero = () => {
  return (
    <div className="herohome">
      <Heroc />
    </div>
  );
};

export default hero;

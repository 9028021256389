import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carouselfasc.scss";

const Carouselfasc = ({ carousel }) => {
  const settings = {
    dots: carousel.length > 1,
    arrows: carousel.length > 1,
    infinite: carousel.length > 1,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for your mobile size
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile screens
        },
      },
    ],
  };
  return (
    <div className="carouselfasc">
      <div className="container-carouselfasc">
        <Slider {...settings}>
          {carousel.map((image, index) => (
            <img className="image" key={index} src={image} alt="foto" />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carouselfasc;

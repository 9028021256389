import imgKiyomi91 from "./img-kiyomi9 (1).webp";
import imgKiyomi92 from "./img-kiyomi9 (2).webp";
import imgKiyomi93 from "./img-kiyomi9 (3).webp";
import imgKiyomi94 from "./img-kiyomi9 (4).webp";
import imgKiyomi95 from "./img-kiyomi9 (5).webp";
import imgKiyomi96 from "./img-kiyomi9 (6).webp";
import imgKiyomi97 from "./img-kiyomi9 (7).webp";
import imgKiyomi98 from "./img-kiyomi9 (8).webp";
import imgKiyomi99 from "./img-kiyomi9 (9).webp";
import imgKiyomi910 from "./img-kiyomi9 (10).webp";
import imgKiyomi911 from "./img-kiyomi9 (11).webp";

const imgKiyomi9 = [
  imgKiyomi91,
  imgKiyomi92,
  imgKiyomi93,
  imgKiyomi94,
  imgKiyomi95,
  imgKiyomi96,
  imgKiyomi97,
  imgKiyomi98,
  imgKiyomi99,
  imgKiyomi910,
  imgKiyomi911,
];

export default imgKiyomi9;

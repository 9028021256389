import fasTerravia1 from "./fas-terravia (1).webp";
import fasTerravia2 from "./fas-terravia (2).webp";
import fasTerravia3 from "./fas-terravia (3).webp";
import fasTerravia4 from "./fas-terravia (4).webp";
import fasTerravia5 from "./fas-terravia (5).webp";
import fasTerravia6 from "./fas-terravia (6).webp";

const fasterravia = [
  fasTerravia1,
  fasTerravia2,
  fasTerravia3,
  fasTerravia4,
  fasTerravia5,
  fasTerravia6,
];

export default fasterravia;

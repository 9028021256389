import React from "react";
import "./devbsd.scss";
import Slider from "react-slick";
import devbsd1 from "../../../../asset/devbsd/devbsd.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const devbsd = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768,
        lazyload: true, // Adjust breakpoint for mobile devices
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile (optional)
          swipeToSlide: true,

          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="devbsd">
      <div className="container-devbsd">
        <div className="header-devbsd">More Committed to Sustainability</div>
        <div className="carousel">
          <Slider {...settings}>
            {devbsd1.map((tanamas, index) => (
              <img className="devb" key={index} src={tanamas} alt={"dev"} />
            ))}
          </Slider>
        </div>
      </div>
      <div className="dividera"></div>
    </div>
  );
};

export default devbsd;

import { faAngleDoubleUp, faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./ctapage.scss";
import { HashLink } from "react-router-hash-link";

const ctapage = () => {
  const waapart = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281319499502&text=Halo%20Reza,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20apart%20https://marketingbsdcity.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="ctapage">
      <div className="header">Pilihan Ruko & Apartment BSD City</div>
      <div className="clusterbutton">
        <div className="nerin">
          <a href="/Commercials">
            <button className="button1">
              <FontAwesomeIcon icon={faBuilding} /> &nbsp; Ruko
            </button>
          </a>
        </div>
        <div className="khione">
          <button className="button" onClick={waapart}>
            <FontAwesomeIcon icon={faBuilding} />
            &nbsp; Apartment
          </button>
        </div>
      </div>
      <div className="buttonhome">
        <HashLink className="link" smooth to="/">
          <button className="page">
            {" "}
            Back Home <FontAwesomeIcon icon={faAngleDoubleUp} />
          </button>
        </HashLink>
        <HashLink className="link" smooth to="/residentials">
          <button className="page">
            {" "}
            Pilihan Rumah <FontAwesomeIcon icon={faAngleDoubleUp} />
          </button>
        </HashLink>
      </div>
    </div>
  );
};

export default ctapage;

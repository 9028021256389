import React from "react";
import "./zora.scss";
import data from "../../data.json";
import Heroc from "../../../component/hero/heroc.jsx";
import Aboutc from "../../../component/about/aboutc.jsx";
import Heroimages from "../../../asset/hero/heroimages.js";
import Promoc from "../../../component/promo/promoc.jsx";
import hsimages from "../../../asset/hardsell/hsimages.js";
import prmo from "../../../component/promo/promocard.json";
import Carouselfasc from "../../../component/carouselfas/carouselfasc.jsx";
import fas from "../../../component/carouselfas/carouselfasc.json";
import faszora from "../../../asset/fascarousel/fas-zora/fas-zora.js";
import dataproduk from "../../../component/cardprodukdetail/cardprodukdetail.json";
import imgKanade8 from "../../../asset/imgrumah/img-zora/img-kanade/img-kanade8/img-kanade8.js";
import imgKanade10 from "../../../asset/imgrumah/img-zora/img-kanade/img-kanade10/img-kanade10.js";
import imgKanade12 from "../../../asset/imgrumah/img-zora/img-kanade/img-kanade12/img-kanade12.js";
import imgKiyomi8 from "../../../asset/imgrumah/img-zora/img-kiyomi/img-kiyomi8/img-kiyomi8.js";
import imgKiyomi9 from "../../../asset/imgrumah/img-zora/img-kiyomi/img-kiyomi9/img-kiyomi9.js";
import imgKiyomi10 from "../../../asset/imgrumah/img-zora/img-kiyomi/img-kiyomi10/img-kiyomi10.js";
import Cardprodukdetail from "../../../component/cardprodukdetail/cardprodukdetail.jsx";
import ScrollToHashElement from "../../home/ScrollToHashElement.js";
const imgMap = {
  faszora,
};
const imageMap = {
  imgKanade8,
  imgKanade10,
  imgKanade12,
  imgKiyomi8,
  imgKiyomi9,
  imgKiyomi10,
};

const zora = () => {
  return (
    <div className="zora">
      <ScrollToHashElement />
      <div className="hero">
        {data.zoraab.map((hero, index) => {
          // Determine the logo dynamically
          const fotopromo = Heroimages[hero.imagehero];
          return (
            <Heroc
              key={index}
              imagehero={fotopromo} // Dynamically import logo images // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="about">
        {data.zoraab.map((home, index) => {
          // Determine the logo dynamically
          return (
            <Aboutc
              key={index}
              judul={home.judul}
              subjudul={home.subjudul} // Dynamically import logo images // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="container-promo">
        {prmo.Zora.map((promo, index) => {
          // Determine the logo dynamically
          const fotopromo = hsimages[promo.imagehs];
          return (
            <Promoc
              key={index}
              imagehs={fotopromo} // Dynamically import logo images
              titlepromo={promo.titlepromo}
              details={promo.details}
              whatsappLink={promo.whatsappLink}
              quote={promo.quote} // Pass the WhatsApp link
            />
          );
        })}
      </div>
      <div className="carouselfas">
        {fas.fasZora.map((slide, index) => {
          return <Carouselfasc key={index} carousel={imgMap[slide.carousel]} />;
        })}
      </div>
      <div id="kanade" className="devider">
        <div className="a">KANADE</div>
      </div>
      <div className="produkcontainer">
        {dataproduk.Kanadep.map((card, index) => {
          return (
            <Cardprodukdetail
              key={index}
              src={imageMap[card.imagesKey]}
              title={card.title}
              sub={card.sub}
              subt={card.subt}
              details={card.details}
              price={card.price}
              whatsappLink={card.whatsappLink}
            />
          );
        })}
      </div>
      <div id="kiyomi" className="devider">
        <div className="a">KIYOMI</div>
      </div>
      <div className="produkcontainer">
        {dataproduk.Kiyomip.map((card, index) => {
          return (
            <Cardprodukdetail
              key={index}
              src={imageMap[card.imagesKey]}
              title={card.title}
              sub={card.sub}
              subt={card.subt}
              details={card.details}
              price={card.price}
              whatsappLink={card.whatsappLink}
            />
          );
        })}
      </div>
    </div>
  );
};

export default zora;

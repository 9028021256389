import fasBlizfield1 from "./fas-blizfield (1).webp";
import fasBlizfield2 from "./fas-blizfield (2).webp";
import fasBlizfield3 from "./fas-blizfield (3).webp";
import fasBlizfield4 from "./fas-blizfield (4).webp";
import fasBlizfield5 from "./fas-blizfield (5).webp";

const fasblizfield = [
  fasBlizfield1,
  fasBlizfield2,
  fasBlizfield3,
  fasBlizfield4,
  fasBlizfield5,
];

export default fasblizfield;

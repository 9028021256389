import React from "react";
import "./lokasi.scss";
import mapsimage from "./maps-dekstop.jpeg";
import mobilemaps from "./maps-mobile.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faGraduationCap,
  faHouse,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";

const lokasi = () => {
  return (
    <div className="lokasi">
      <div className="container-lokasi">
        <div className="header-lokasi">Lokasi</div>
        <div className="content">
          <div className="maps">
            <img
              id="lokasi"
              className="img-lokasi-dekstop"
              src={mapsimage}
              alt="maps"
            />
            <img
              id="lokasi"
              className="img-lokasi-mobile"
              src={mobilemaps}
              alt="maps"
            />
          </div>
          <div className="ket">
            <div className="point1">
              <div className="detail1">
                <div className="angka">6k+</div>
                <div className="desk">Hectars</div>
              </div>
              <div className="detail2">
                <div className="angka">40k</div>
                <div className="desk">Houses Build</div>
              </div>
              <div className="detail3">
                <div className="angka">46ha</div>
                <div className="desk">Entertainment & Malls</div>
              </div>
            </div>
            <div className="point2">
              <div className="detail1">
                <div className="angka">450k</div>
                <div className="desk">Populations</div>
              </div>
              <div className="detail2">
                <div className="angka">25ha</div>
                <div className="desk">Green Office Park</div>
              </div>
              <div className="detail3">
                <div className="angka">7</div>
                <div className="desk">Big Universities</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefit-container">
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faGraduationCap} /> &nbsp;Education
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Binus University
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;IPK School
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;BPK Penabur
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Al-Azhar BSD School
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Prastya Mulya University
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Atmajaya University
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Pelita Harapan University
            </div>
          </div>
        </div>
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faHouse} /> &nbsp;Health Care
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Bethsaida Hospital
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Eka Hospital
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Mayapada Hospital
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;BSD Dental Centre
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;RS Columbia Asia BSD Hospital
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Hermina Hospital
            </div>
          </div>
        </div>
        <div className="benefit-point">
          <div className="benefit-title">
            <FontAwesomeIcon icon={faBuilding} /> &nbsp;Entertainment
          </div>
          <div className="benefit">
            <div className="point">
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Grand Lucky Super Store
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Indonesia Convention Exhibition
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Eastvara Mall BSD
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;BSD Xtreme Park
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Botanical Park
              <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;The Quantis Club <br />
              <FontAwesomeIcon icon={faMapPin} />
              &nbsp;&nbsp;Damai Indah Golf
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default lokasi;

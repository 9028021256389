import React from "react";
import "./produk.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderproduk from "../../../../asset/sliderproduk/sliderproduk";
import Cardprodukhome from "../../../../component/cardprodukhome/cardprodukhome.jsx";
import logop from "../../../../asset/logo/logo.js";
import data from "../../../../component/cardprodukhome/cardprodukhome.json";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { HashLink } from "react-router-hash-link";
const produk = () => {
  const wap = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281319499502&text=Halo%20Reza,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://marketingbsdcity.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768,
        lazyload: true, // Adjust breakpoint for mobile devices
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile (optional)
          swipeToSlide: true,

          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="produk">
      <div className="container-produk">
        <div id="rumah" className="header-produk">
          <div className="judul-produk">Residential's</div>
          <div className="carousel-produk">
            <Slider {...settings}>
              {sliderproduk.map((tanamas, index) => (
                <img className="devb" key={index} src={tanamas} alt={"dev"} />
              ))}
            </Slider>
          </div>
        </div>
        <div className="container-cardproduk">
          {data.cardh.map((promo, index) => {
            // Determine the logo dynamically
            const fotopromo = logop[promo.logoimg];
            return (
              <Cardprodukhome
                key={index}
                judulcard={promo.judulcard} // Dynamically import logo images
                harga={promo.harga}
                logoimg={fotopromo}
                linkpage={promo.linkpage} // Pass the WhatsApp link
              />
            );
          })}
        </div>
      </div>
      <div className="button">
        <div className="buttonwa2">
          <button onClick={wap} className="whatsapp">
            {" "}
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
          </button>
        </div>
        <div className="buttonwa3">
          <HashLink className="link" smooth to="/residentials">
            <button className="page">
              {" "}
              Detail Product <FontAwesomeIcon icon={faAngleRight} />
            </button>
          </HashLink>
        </div>
      </div>
      <div className="dividerb"></div>
    </div>
  );
};

export default produk;

import imgWynyard81 from "./Wynyard 8.webp";
import imgWynyard82 from "./Wynyard 8 (2).webp";
import imgWynyard83 from "./Wynyard 8 (3).webp";
import imgWynyard84 from "./Wynyard 8 (4).webp";
import imgWynyard85 from "./Wynyard 8 (5).webp";
import imgWynyard86 from "./Wynyard 8 (6).webp";
import imgWynyard87 from "./Wynyard 8 (7).webp";
import imgWynyard88 from "./Wynyard 8 (8).webp";
import imgWynyard89 from "./Wynyard 8 (9).webp";

const imgWynyard8 = [
  imgWynyard81,
  imgWynyard82,
  imgWynyard83,
  imgWynyard84,
  imgWynyard85,
  imgWynyard86,
  imgWynyard87,
  imgWynyard88,
  imgWynyard89,
];

export default imgWynyard8;
